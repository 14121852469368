export default {
  pricingOptions: [
    {
      id: 1,
      shape: 'icon_shape1.png',
      icon: 'ti-panel',
      text: 'Quick & Easy',
      descriptionList: [
        '$12k',
        '2 weeks',
        'Legal Industry',
        'Automation App',
      ],
      description: 'We digitized a 30+ page intake form enabling dynamic field changes, real time tracking and automated submission. This took our client’s processing time from over 2 hours to less than 30 minutes.',
      deliverables: [
        'Interactive web app',
        'User account management',
        'Automated email notifications',
        'Customizable form',
        'Database integration',
      ],
    }, {
      id: 2,
      shape: 'icon_shape2.png',
      icon: 'ti-layout-grid2',
      text: 'Tiger Team Take Over',
      description: 'A multinational client needed to collect and assimilate high volume data from newly embedded sensors. This R&D project enabled the field service team to work more efficiently and the executive team to track post-sale product activity.',
      descriptionList: [
        '$60k 10 weeks',
        'Manufacturing Industry IoT',
        'Management POC',
      ],
      deliverables: [
        'Mobile app',
        'High volume data pipeline',
        'Rapid prototyping sessions',
        'Machine learning data warehouse',
        'Data analysis dashboard',
      ],
    }, {
      id: 3,
      shape: 'icon_shape3.png',
      icon: 'ti-gallery',
      text: 'Enterprise MVP',
      description: 'A corporation with over 10 million users needed an integrated ticketing system that ensured clients received the appropriate support response immediately. We utilized Natural Language Understanding to build an app that properly tracked and scheduled requests and enabled user-based permissioning by integrating their existing ecosystem.',
      descriptionList: [
        '$350k 6 months',
        'Facilities Management Industry',
        'Enterprise Systems Integration',
      ],
      deliverables: [
        'Chatbot NLP interface',
        'Integration with existing legacy systems',
        'Corporate IT compliance',
        '10 million user base capacity',
        'Bank level security',
        '> 95% test coverage',
        'Expansive documentation for handoff to internal development team',
      ],
    },
  ],
  questions: [
    {
      title: 'But what if a competitor is charging less?',
      description: 'Our rates are already highly competitive because of our low overhead. In considering your options, it’s important to be aware that you will get what you pay for. We are senior engineers and architects delivering the highest quality on everything we work on. We’ve brought on many clients that initially went with the ‘cheap’ option only to realize that they couldn’t go to market with the cheap end-product that was delivered. They end up spending more just to undo the damage done before having to start over with us.',
    },
    {
      title: 'What is the benefit of hiring you for a project instead of hourly?',
      description: 'Hourly hiring is the best solution if you are looking to have one of our engineers report directly to you for general needs over a specific amount of time. The most appropriate example of this would be when you’re bridging for low headcount. When you have an end-product in mind the best solution is to hire us as an agency for your project. This provides you with a concise cost estimate and delivery timeframe for your product. It also grants you access to the collective brain power at OPNA at a flat rate that will always end up being the most cost effective way for you to achieve your goals. If you have ongoing, intermittent needs the best engagement model for you is retainer. This way you have our team on stand-by with guaranteed response & resolution times to ensure you have 100% uptime and peace of mind.',
    },
    {
      title: 'Can you meet our security requirements?',
      description: 'OPNA is fully versed on all security compliance requirements across multiple sectors. We can assure adherence to industry mandated regulations and work within those frameworks, as well as your unique company policies, to develop your product.',
    },
    {
      title: 'How does your staff augmentation model work?',
      description: 'Embedding one of our developers is easier and more efficient than hiring. You can expect a full-time employee without the traditional cost of on-boarding and benefits. We will work at a predetermined hourly rate remotely or partially-onsite at your discretion. You can also disengage us as quickly and easily as you engaged us.',
    },
    {
      title: 'I’m working on highly sensitive projects. Can you ensure confidentiality?',
      description: 'We work almost exclusively on proprietary products. You own all work delivered and we are able to work off your NDA so that all your considerations are addressed. We can also connect you with client referrals upon request.',
    },
    {
      title: 'Can you design my web page or mobile app?',
      description: 'Absolutely! We have full design services and can help you design an app that is on brand.',
    },
    {
      title: 'Can you implement our design work?',
      description: 'Yes, we can implement any of the design you already have. We will need the files (photoshop, sketch, or software of your choosing) as well as a consultation to go over any expected interactivity with the provided designs once they are live.',
    },
    {
      title: 'How soon can you start?',
      description: 'Usually in 14 days, depending on the resources needed. We excel at woking within important timelines.',
    },
    {
      title: 'How will you scope my project?',
      description: 'The length of the scoping phase is entirely dependent on the complexity of your project. First, we identify your requirements and provide you with a non-binding estimate based on previous similar projects. We then enter a discovery phase consisting of Strategy & Proposal Sprints (usually 2 weeks each) where we hold stakeholder interviews to build user stories and develop our milestone plan. These deliverables allow us to then generate a highly accurate scope of work, including project cost. Once you approve, we then begin development and meet at each milestone to review and rescope as needed.',
    },
    {
      title: 'How do you communicate during a project?',
      description: 'We advise checking in at least once a week, at the beginning and midpoint of each milestone. We are always available for a call and are happy to adopt your internal communication platforms while working with you.',
    },
  ],
};
